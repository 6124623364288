<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>

<template>
    <footer class="footer">
        <div class="footer-content">
            <p class="copyright">
                {{ $t('copyright', { year: currentYear }) }}
            </p>
            <p class="info-text">
                {{ $t('infoText') }}
            </p>
        </div>
    </footer>
</template>



<style scoped>
.footer {
    text-align: center;
    padding: 20px;
}

.footer-content {
    max-width: 800px;
    margin: 0 auto;
}

.copyright {
    font-size: 14px;
    margin: 5px 0;
}

.info-text {
    font-size: 12px;
    color: #6c757d;
}
</style>
