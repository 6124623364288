// src/metaInfo.js

export const metaInfo = {
  home: {
    title: 'HabboUtils - One Utility for Everything You Need in Habbo',
    meta: [
      { name: 'description', content: 'HabboUtils is your all-in-one platform for everything related to Habbo. Fast, lightweight, and responsive, featuring tools for badges, profiles, wardrobe, and more.' },
      { name: 'keywords', content: 'HabboUtils, Habbo tools, Habbo profile checker, Habbo badges, Habbo imager, Habbo marketplace, Habbo NFT, Habbo avatar imager, Habbo wardrobe, profile picture generator' },
      { property: 'og:title', content: 'HabboUtils - One Utility for Everything You Need in Habbo' },
      { property: 'og:description', content: 'Fast, responsive, and feature-packed tools for Habbo users.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.habboutils.com/' },
    ],
  },
  usertracker: {
    title: 'User Tracker - Monitor Habbo Profiles with HabboUtils',
    meta: [
      { name: 'description', content: 'Track and monitor Habbo user profiles, including their friends, badge collections, and achievements. A powerful tool for Habbo enthusiasts.' },
      { name: 'keywords', content: 'Habbo profile tracker, HabboUtils, Habbo badges, Habbo friends, Habbo user tracking, profile monitoring' },
      { property: 'og:title', content: 'User Tracker - Monitor Habbo Profiles with HabboUtils' },
      { property: 'og:description', content: 'Check and track Habbo profiles and friends easily with HabboUtils.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.habboutils.com/usertracker' },
    ],
  },
  badges: {
    title: 'Habbo Badges - View and Collect with HabboUtils',
    meta: [
      { name: 'description', content: 'Explore and manage your Habbo badges and achievements easily. Unlock rare badges and show off your accomplishments.' },
      { name: 'keywords', content: 'Habbo badges, achievements, rare badges, Habbo tools, HabboUtils' },
      { property: 'og:title', content: 'Habbo Badges - View and Collect with HabboUtils' },
      { property: 'og:description', content: 'Manage your Habbo badges and track your achievements with HabboUtils.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.habboutils.com/badges' },
    ],
  },
  comingsoon: {
    title: 'Coming Soon - New Features on HabboUtils',
    meta: [
      { name: 'description', content: 'Exciting new features are coming soon to HabboUtils. Stay updated and be the first to try our latest tools for Habbo users.' },
      { name: 'keywords', content: 'coming soon, new features, updates, Habbo tools, HabboUtils' },
      { property: 'og:title', content: 'Coming Soon - New Features on HabboUtils' },
      { property: 'og:description', content: 'Stay tuned for new tools and features coming soon to HabboUtils.' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.habboutils.com/comingsoon' },
    ],
  },
};

export const defaultMetaInfo = {
  title: 'HabboUtils - Your One-Stop Solution for Habbo Tools',
  meta: [
    { name: 'description', content: 'HabboUtils offers the best tools and resources for Habbo users, including profile trackers, badge management, and more.' },
    { name: 'keywords', content: 'Habbo tools, Habbo profile checker, Habbo badges, Habbo avatar imager, Habbo wardrobe' },
    { property: 'og:title', content: 'HabboUtils - Your One-Stop Solution for Habbo Tools' },
    { property: 'og:description', content: 'All the tools you need for Habbo in one place - profile checker, badges, and more.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.habboutils.com/' },
  ],
};
