<template>
  <header>
    <div class="lang-switcher">
      <div class="box" @click="toggleMenu" aria-haspopup="menu" aria-expanded="isMenuOpen">
        <img
          class="flag"
          :src="getFlagSrc(selectedLanguage)"
          :alt="selectedLanguage"
        />
      </div>
      <div v-if="isMenuOpen" class="language-dropdown">
        <div
          v-for="lang in availableLanguages"
          :key="lang"
          @click="changeLanguage(lang)"
          class="language-option"
        >
          <img
            class="flag"
            :src="getFlagSrc(lang)"
            :alt="lang"
          />
          {{ lang.toUpperCase() }}
        </div>
      </div>
    </div>

    <div class="header-container">
      <router-link id="header_logo" to="/">HabboUtils</router-link>
    </div>
  </header>

  <nav class="container">
    <div class="nav-buttons">
      <router-link to="/usertracker">
        <FontAwesomeIcon :icon="['fas', 'user']" /> {{ $t('users') }}
      </router-link>
      <router-link to="/badges">
        <FontAwesomeIcon :icon="['fas', 'star']" /> {{ $t('badges') }}
      </router-link>
      <div class="dropdown">
        <router-link to="/soon" class="dropdown__toplevel">
          <FontAwesomeIcon :icon="['fas', 'chair']" /> {{ $t('marketplace') }}
        </router-link>
        <div class="dropdown__menu">
          <router-link to="/soon">{{ $t('marketplacetracker') }}</router-link>
          <router-link to="/soon">{{ $t('nfttracker') }}</router-link>
        </div>
      </div>
      <router-link to="/soon">
        <FontAwesomeIcon :icon="['fas', 'users']" /> {{ $t('team') }}
      </router-link>
    </div>
    <div class="nav-right-buttons"></div>
  </nav>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faStar,
  faChair,
  faUsers,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

library.add(faUser, faStar, faChair, faUsers, faSearch);

export default {
  name: "Navbar",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      selectedLanguage: sessionStorage.getItem('selectedLanguage') || this.$i18n.locale,
      isMenuOpen: false,
    };
  },
  computed: {
    availableLanguages() {
        const allLanguages = ['en', 'de', 'tr', 'es', 'nl', 'fr', 'fi', 'it'];
        return allLanguages.filter(lang => lang !== this.selectedLanguage);
    },
  },
  watch: {
    selectedLanguage(newLang) {
      sessionStorage.setItem('selectedLanguage', newLang);
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    changeLanguage(lang) {
      this.selectedLanguage = lang;
      this.isMenuOpen = false;
      this.$i18n.locale = lang;
    },
    getFlagSrc(lang) {
      return require(`@/assets/img/flags/${lang}.png`);
    },
  },
  mounted() {
    const savedLanguage = sessionStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      this.$i18n.locale = savedLanguage;
    }
  },
};
</script>

<style scoped>
@import "@/assets/NavBar.css";
</style>
