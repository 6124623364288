import { createI18n } from 'vue-i18n';

// Function to load locale messages dynamically
const loadLocaleMessages = () => {
    const locales = {};
    const requireContext = require.context('./', true, /\.json$/);

    requireContext.keys().forEach((file) => {
        const locale = file.split('/')[1]; // Adjust if necessary
        const messages = requireContext(file);
        
        if (!locales[locale]) {
            locales[locale] = {};
        }
        locales[locale] = { ...locales[locale], ...messages }; // Merge messages
    });

    return locales;
};


// Detect the device's language or fallback to 'en' if not available
const getBrowserLanguage = () => {
    const browserLang = navigator.language || navigator.userLanguage;
    const availableLocales = Object.keys(loadLocaleMessages());
    return availableLocales.includes(browserLang) ? browserLang : 'en'; // Fallback to 'en' if the detected language is not supported
};

// Create the i18n instance with detected language
const i18n = createI18n({
    locale: getBrowserLanguage(), // Use detected language as the default locale
    fallbackLocale: 'en', // Fallback to German if translation is unavailable
    messages: loadLocaleMessages(), // Load the translations
});

export default i18n;
