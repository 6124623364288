import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSearch, faTrash, faSync } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { debounce } from 'lodash';

export default defineComponent({
  components: {
    FontAwesomeIcon
  },

  setup() {
    const { t } = useI18n();

    const searchTerm = ref("");
    const searchFriendTerm = ref("");
    const userData = ref(null);
    const searches = ref(JSON.parse(localStorage.getItem("userTrackerSearches")) || []);
    const error = ref("");
    const friends = ref([]);
    const displayedFriends = ref([]);
    const friendsPage = ref(1);
    const friendsPerPage = 15;
    const showTooltip = ref(false); // Track tooltip visibility

    const isSearching = computed(() => searchFriendTerm.value.length > 0);
    const hasMoreFriends = computed(() => !isSearching.value && friends.value.length > displayedFriends.value.length);

    const filteredFriends = computed(() => {
      if (isSearching.value) {
        return friends.value.filter(friend =>
          friend.name.toLowerCase().includes(searchFriendTerm.value.toLowerCase())
        );
      }
      return displayedFriends.value;
    });

    watch(searchFriendTerm, () => {
      debouncedUpdateDisplayedFriends();
    });

    const debouncedUpdateDisplayedFriends = debounce(() => {
      updateDisplayedFriends();
    }, 300);

    const updateDisplayedFriends = () => {
      if (!isSearching.value) {
        const start = (friendsPage.value - 1) * friendsPerPage;
        const end = start + friendsPerPage;
        displayedFriends.value = friends.value.slice(0, end);
      }
    };

    const fetchUserData = async (username, saveToHistory = true) => {
      try {
        const response = await axios.get(`https://www.habbo.de/api/public/users?name=${username}`);
        userData.value = response.data;
        error.value = "";

        if (saveToHistory) {
          updateSearchHistory(response.data);
        }

        resetFriendsState();
        await fetchFriends(response.data.uniqueId);
      } catch (err) {
        handleError(err, "errorNotFound");
      }
    };

    const updateSearchHistory = (data) => {
      const existingSearch = searches.value.find(search => search.uniqueId === data.uniqueId);
      if (existingSearch) {
        existingSearch.lastSearchTimestamp = Date.now();
      } else {
        searches.value.unshift({ ...data, lastSearchTimestamp: Date.now() });
      }

      // Sort the searches by timestamp (newest first)
      searches.value.sort((a, b) => b.lastSearchTimestamp - a.lastSearchTimestamp);

      // Save to localStorage
      localStorage.setItem("userTrackerSearches", JSON.stringify(searches.value));
    };

    const handleError = (err, messageKey) => {
      error.value = t(messageKey);
      console.error(err);
    };

    const fetchFriends = async (uniqueId) => {
      try {
        const response = await axios.get(`https://www.habbo.com/api/public/users/${uniqueId}/friends`);
        friends.value = response.data;
        updateDisplayedFriends();
      } catch (err) {
        handleError(err, "errorLoadingFriends");
      }
    };

    const loadMoreFriends = () => {
      if (!isSearching.value && hasMoreFriends.value) {
        friendsPage.value++;
        updateDisplayedFriends();
      }
    };

    const resetFriendsState = () => {
      friends.value = [];
      displayedFriends.value = [];
      friendsPage.value = 1;
    };

    const handleSearch = () => {
      if (searchTerm.value) {
        fetchUserData(searchTerm.value);
        searchFriendTerm.value = ""; // Clear friend search when searching for a new user
      }
    };

    const deleteAllSearches = () => {
      searches.value = [];
      localStorage.removeItem("userTrackerSearches"); // Clear history from localStorage
    };

    const refreshOnlineStatus = async () => {
      const currentUserData = userData.value;

      searches.value = await Promise.all(
        searches.value.map(async (search) => {
          try {
            const response = await axios.get(`https://www.habbo.de/api/public/users?name=${search.name}`);
            return { ...response.data, lastSearchTimestamp: search.lastSearchTimestamp };
          } catch (err) {
            return search; // Return original data in case of error
          }
        })
      );

      if (currentUserData) {
        try {
          const response = await axios.get(`https://www.habbo.de/api/public/users?name=${currentUserData.name}`);
          userData.value = response.data;
        } catch (err) {
          console.error(err);
        }
      }

      // Sort searches after refreshing and save to localStorage
      searches.value.sort((a, b) => b.lastSearchTimestamp - a.lastSearchTimestamp);
      localStorage.setItem("userTrackerSearches", JSON.stringify(searches.value));
    };

    onMounted(() => {
      refreshOnlineStatus();
    });

    const getFormattedTime = (timestamp) => {
      return new Date(timestamp).getTime();
    };

    const toggleTooltip = () => {
      showTooltip.value = !showTooltip.value; // Toggle tooltip visibility
    };


    return {
      searchTerm,
      searchFriendTerm,
      userData,
      searches,
      error,
      friends,
      filteredFriends,
      displayedFriends,
      friendsPage,
      hasMoreFriends,
      isSearching,
      fetchUserData,
      handleSearch,
      deleteAllSearches,
      refreshOnlineStatus,
      loadMoreFriends,
      faSearch,
      faTrash,
      faSync,
      toggleTooltip,
      showTooltip,
      getFormattedTime,
      handleFriendItemClick: (username) => {
        searchTerm.value = username;
        handleSearch();
        searchFriendTerm.value = ""; // Clear friend search when a friend is clicked
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      handleHistoryItemClick: (username) => {
        searchTerm.value = username;
        handleSearch();
        searchFriendTerm.value = ""; // Clear friend search when history item is clicked
      },
    };
  },
});
