// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserTracker from './components/UserTracker.vue';
import BadgeList from './components/BadgeList.vue';
import ComingSoon from './components/ComingSoon.vue';
import Home from './components/Home.vue';
import { metaInfo } from '@/metaInfo'; // Import the centralized meta info

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: metaInfo.home, // Meta info for the Home route
  },
  {
    path: '/usertracker',
    name: 'UserTracker',
    component: UserTracker,
    meta: metaInfo.usertracker, // Meta info for the User Tracker route
  },
  {
    path: '/badges',
    name: 'BadgeList',
    component: BadgeList,
    meta: metaInfo.badges, // Meta info for the Badges route
  },
  {
    path: '/soon',
    name: 'ComingSoon',
    component: ComingSoon,
    meta: metaInfo.comingsoon, // Meta info for the Coming Soon route
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
