<template>
  <div class="badge-container">
    <h2 class="box__title-flat">{{ $t('filterResults') }}</h2>
    <div class="box__content">
      <div class="badge-filters">
        <div class="badge-filters__container">
          <div class="badge-filters__section">
            <input
              type="text"
              v-model="searchQuery"
              :placeholder="$t('searchBadge')"
              @input="updateFilters"
            />
            <select v-model="sortOrder" @change="updateFilters">
              <option value="default">{{ $t('newestToOldest') }}</option>
              <option value="oldest">{{ $t('oldestToNewest') }}</option>
            </select>
          </div>
          <div class="badge-filters__checkbox-group">
            <div class="checkbox-container">
              <label>
                <input type="checkbox" v-model="showAchievements" @change="updateFilters">
                {{ $t('achievements') }}
              </label>
            </div>
            <div class="checkbox-container">
              <label>
                <input type="checkbox" v-model="showUnused" @change="updateFilters">
                {{ $t('unusedBadges') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-bottom: 10px; color: rgba(255, 255, 255, 0.6); font-size: 20px; margin-left: 28px;">
      {{ totalResults }} {{ $t('results') }}
    </div>

    <div class="badge__grid">
      <div v-for="badge in paginatedBadges" :key="badge.code" class="badge">
        <div class="badge__image">
          <img
            :src="badge.image"
            loading="lazy"
            :alt="badge.name || badge.code"
            @load="imageLoaded(badge.code)"
            @error="imageError(badge.code)"
            onerror="this.style.display='none';"
          />
        </div>
        <div class="badge__code">{{ badge.code }}</div>
        <div class="badge__tooltip" :data-tooltip="badge.name || badge.code"></div>
      </div>
    </div>

    <Pagination
      :currentPage="currentPage"
      :totalItems="filteredBadges.length"
      @update:currentPage="changePage"
    />
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      totalResults: 0,
      badges: [],
      searchQuery: '',
      sortOrder: 'default',
      showAchievements: false,
      showUnused: false,
      debounceTimeout: null,
    };
  },
  computed: {
    filteredBadges() {
      let filtered = this.badges;

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(badge =>
          badge.code.toLowerCase().includes(query) ||
          (badge.name && badge.name.toLowerCase().includes(query))
        );
      }

      if (this.showAchievements) {
        filtered = filtered.filter(badge => badge.code && badge.code.startsWith("ACH_"));
      }

      if (this.showUnused) {
        filtered = filtered.filter(badge => (!badge.name || badge.name.trim() === "") &&
                                            (!badge.description || badge.description.trim() === ""));
      }

      if (this.sortOrder === 'oldest') {
        filtered.sort((a, b) => a.date - b.date);
      } else if (this.sortOrder === 'default') {
        filtered.sort((a, b) => b.date - a.date);
      }

      this.totalResults = filtered.length;
      return filtered;
    },
    paginatedBadges() {
      const start = (this.currentPage - 1) * 110;
      return this.filteredBadges.slice(start, start + 110);
    },
  },
  async created() {
    await this.fetchBadges();
  },
  methods: {
    async fetchBadges() {
      try {
        const response = await fetch("https://api.canee.tech/api/badges");
        if (response.ok) {
          const data = await response.json();
          this.badges = this.extractBadgeData(data);
          this.totalResults = this.badges.length;
        } else {
          console.error("Error fetching data:", response.status);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    },
    extractBadgeData(badgeList) {
      const imageBaseUrl = "https://images.habbo.com/c_images/album1584/";

      return badgeList.map(badge => ({
        ...badge,
        image: `${imageBaseUrl}${badge.code}.png`,
        date: new Date(), // Replace with actual date logic if available
      }));
    },
    imageLoaded(code) {
      // Handle image load logic if necessary
    },
    imageError(code) {
      // Handle image error logic if necessary
    },
    changePage(newPage) {
      this.currentPage = newPage;
      const top = this.$el.getBoundingClientRect().top + window.scrollY - 80;
      setTimeout(() => {
        window.scrollTo({ top, behavior: "smooth" });
      }, 300);
    },
    updateFilters() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.currentPage = 1; // Reset pagination to page 1
        this.totalResults = this.filteredBadges.length; // Update total results count
      }, 300); // 300ms delay
    },
  },
};
</script>

<style scoped>
@import "@/assets/BadgeList.css";
</style>
