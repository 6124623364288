<template>
  <div id="app">
    <Navbar />
    <!-- Navbar always present -->
    <router-view />
    <!-- Dynamically loads the components based on the route -->
    <Footer />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'; // For accessing the current route
import { useHead } from '@unhead/vue'; // For setting dynamic meta tags
import { computed } from 'vue'; // To create a computed property
import Navbar from './components/NavBar.vue'; // Import the Navbar component
import Footer from './components/Footer.vue'; // Import the Footer component
import { defaultMetaInfo } from '@/metaInfo'; // Import default meta info

// Get the current route
const route = useRoute();

// Create a computed property that dynamically assigns the meta tags based on the route
const meta = computed(() => route.meta || defaultMetaInfo);

// Use useHead with computed to dynamically set meta tags based on the current route
useHead({
  title: () => meta.value.title,
  meta: () => meta.value.meta,
});
</script>

<style>
/* Global styles */
</style>
