<template>
  <div class="home-container">
    <header class="hero-section">
      <h1>{{ $t('hometitle') }}</h1>
      <p>{{ $t('subtitle') }}</p>
    </header>
    <div class="content">
      <section class="features">
        <h2>{{ $t('features.title') }}</h2>
        <div class="feature-list">
          <div class="feature-item">
            <h3>{{ $t('features.profileViewer.title') }}</h3>
            <p>{{ $t('features.profileViewer.description') }}</p>
          </div>
          <div class="feature-item">
            <h3>{{ $t('features.badgeViewer.title') }}</h3>
            <p>{{ $t('features.badgeViewer.description') }}</p>
          </div>
          <div class="feature-item">
            <h3>{{ $t('features.marketplaceViewer.title') }}</h3>
            <p>{{ $t('features.marketplaceViewer.description') }}</p>
          </div>
          <div class="feature-item">
            <h3>{{ $t('features.nftMarket.title') }}</h3>
            <p>{{ $t('features.nftMarket.description') }}</p>
          </div>
          <div class="feature-item">
            <h3>{{ $t('features.wardrobe.title') }}</h3>
            <p>{{ $t('features.wardrobe.description') }}</p>
          </div>
        </div>
      </section>
      <section class="about">
        <h2>{{ $t('about.title') }}</h2>
        <p>{{ $t('about.description') }}</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage"
};
</script>

<style scoped>
.home-container {
  padding: 20px;
  margin: auto;
  max-width: 1200px;
}

.hero-section {
  text-align: center;
  background-color: #2a3b57; /* Match navbar background */
  color: #fff;
  border-radius: 10px;
  margin: 30px auto; /* Center align with auto margins */
}

.features,
.about {
  margin-bottom: 30px;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.feature-item {
  background: var(--item-bg-color);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 0 0 250px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.feature-item h3 {
  margin-bottom: 10px;
}

.about p {
  font-size: 1.1em;
  line-height: 1.6;
  text-align: center;
}
</style>
