import { createStore } from 'vuex';

export default createStore({
  state: {
    // Define your state properties here
  },
  mutations: {
    // Define your mutations here
  },
  actions: {
    // Define your actions here
  },
  getters: {
    // Define your getters here
  }
});
