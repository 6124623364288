<template>
  <div class="user-tracker">
    <h1>{{ $t('title') }}</h1>
    <form @submit.prevent="handleSearch" class="search-form">
      <input
        v-model="searchTerm"
        type="text"
        :placeholder="$t('searchPlaceholder')"
      />
      <button type="submit"><FontAwesomeIcon :icon="faSearch" /> {{ $t('searchButton') }}</button>
    </form>

    <p v-if="error" class="error">{{ error }}</p>

    <div class="main-content">
      <div class="user-card-container">
        <div v-if="userData && !error" class="user-card" style="text-align: center;">
          <img
            :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${userData.figureString}&gesture=std&action=std&direction=2&head_direction=3&headonly=0`"
            :alt="userData.name"
            class="avatar"
          />
          <h2>{{ userData.name }}</h2>
          <p>{{ $t('motto') }}: <span class="motto">{{ userData.motto || $t('noMotto') }}</span></p>
          <p :class="userData.online ? 'online' : 'offline'">{{ userData.online ? $t('online') : $t('offline') }}</p>
          <p v-if="userData.lastAccessTime">
      {{ $t('lastLogin') }}:
      <span
        class="last-login-time"
        :data-tooltip="lastLoginTooltip"
        @click="toggleTooltip"
        @blur="hideTooltip"
        tabindex="0">
        {{ formatLastAccessTime(userData.lastAccessTime) }}
      </span>
    </p>
          <p>{{ $t('memberSince') }}: {{ new Date(userData.memberSince).toLocaleDateString() }}</p>

          <div v-if="userData.selectedBadges && userData.selectedBadges.length" class="badges">
            <h2>{{ $t('activeBadges') }}:</h2>
            <ul class="badge-list">
              <li
                v-for="(badge, index) in userData.selectedBadges"
                :key="index"
                class="badge-item"
              >
                <div class="badge-icon-container" :title="badge.name">
                  <img
                    :src="`https://images.habbo.com/c_images/album1584/${badge.code}.png`"
                    :alt="badge.name"
                    class="badge-icon"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="search-history-container">
        <h2>{{ $t('searchHistory') }}</h2>
        <div class="history-actions">
          <button @click="deleteAllSearches" class="delete-all">
            <FontAwesomeIcon :icon="faTrash" /> {{ $t('deleteAll') }}
          </button>
          <button @click="refreshOnlineStatus" class="refresh-all">
            <FontAwesomeIcon :icon="faSync" /> {{ $t('refreshStatus') }}
          </button>
        </div>

        <div class="history-list-wrapper">
          <div class="history-list">
            <div
              v-for="(search, index) in searches"
              :key="index"
              class="history-item"
              @click="handleHistoryItemClick(search.name)"
            >
              <img
                :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${search.figureString}&gesture=std&action=std&direction=2&head_direction=3&headonly=0`"
                :alt="search.name"
                class="avatar-small"
              />
              <span>{{ search.name }}</span>
              <span :class="search.online ? 'online' : 'offline'">
                {{ search.online ? $t('online') : $t('offline') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="friends.length && !error" class="friends-section">
      <h2>{{ $t('friends') }}</h2>
              
      <div class="friends-list">
        <input
          v-model="searchFriendTerm"
          type="text"
          :placeholder="$t('searchfriend')"
          @input="debouncedUpdateFriendList"
          class="search-input"
        />

        <div v-for="(friend, index) in filteredFriends" :key="friend.uniqueId" class="friend-item" @click="handleFriendItemClick(friend.name)">
          <img
            :src="`https://www.habbo.de/habbo-imaging/avatarimage?size=l&figure=${friend.figureString}&gesture=std&action=std&direction=2&head_direction=3&headonly=0`"
            :alt="friend.name"
            class="avatar-small"
          />
          <span>{{ friend.name }}</span><br />
          <div class="motto"><span>{{ friend.motto || $t('noMotto') }}</span></div>

          <span :class="friend.online ? 'online-badge' : 'offline-badge'">
            {{ friend.online ? $t('online') : $t('offline') }}
          </span>
        </div>
      </div>

      <button v-if="hasMoreFriends" @click="loadMoreFriends" class="load-more">
        <font-awesome-icon :icon="faSync" /> {{ $t('loadMore') }}
      </button>
    </div>
  </div>
</template>

<script>
import logic from "./UserTracker.js";

export default {
  ...logic,
  computed: {
    lastLoginTooltip() {
      return this.userData && this.userData.lastAccessTime
        ? this.formatLastAccessTime(this.userData.lastAccessTime, true)
        : '';
    }
  },
  methods: {
    formatLastAccessTime(lastAccessTime, isTooltip = false) {
      const now = new Date();
      const diffInSeconds = Math.floor((now - new Date(lastAccessTime)) / 1000);

      // Get the current language from i18n
      const currentLocale = this.$i18n.locale;

      const rtf = new Intl.RelativeTimeFormat(currentLocale, { numeric: 'auto' });

      // Return human-readable time or a formatted string
      if (isTooltip) {
        return new Date(lastAccessTime).toLocaleString(currentLocale); // Format as a human-readable date/time
      }

      if (diffInSeconds < 60) {
        return rtf.format(-diffInSeconds, 'second');
      } else if (diffInSeconds < 3600) {
        return rtf.format(-Math.floor(diffInSeconds / 60), 'minute');
      } else if (diffInSeconds < 86400) {
        return rtf.format(-Math.floor(diffInSeconds / 3600), 'hour');
      } else if (diffInSeconds < 31536000) {
        return rtf.format(-Math.floor(diffInSeconds / 86400), 'day');
      } else {
        return rtf.format(-Math.floor(diffInSeconds / 31536000), 'year');
      }
    }
  }
}
</script>


<style scoped>
@import "@/assets/UserTracker.css";
</style>
