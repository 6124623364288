<template>
  <div class="coming-soon-container">
    <h1>Coming Soon!</h1>
    <p>
      We are excited to announce that new features are on the way. Stay tuned!
    </p>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
};
</script>

<style scoped>
r h1 {
  font-size: 2.5em;
  color: #333;
}

p {
  font-size: 1.2em;
  color: #fff;
  text-align: center;
}
</style>
