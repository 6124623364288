<template>
  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1">{{ $t('previous') }}</button>

    <span>{{ $t('page') }} {{ currentPage }} {{ $t('of') }} {{ totalPages }}</span>

    <button @click="nextPage" :disabled="currentPage === totalPages">
      {{ $t('next') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit("update:currentPage", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("update:currentPage", this.currentPage + 1);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
}
</style>
